@import "src/assets/scss/abstract";































































































































































.actions {
  .spinner-border {
    width: 1em;
    height: 1em;
  }
}
